import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import { NavPane } from './NavPane';
import { TenantMigrationStatusList } from './TenantMigrationStatusList';

import { backendGet } from './BackendClient';

const stackTokens = {
    childrenGap: 15,
};

export class App extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            authorizationCheckInProgress: true,
            authorizedComponents: [],
            activeNavLink: 'Migration_Status',
        };
        
        this.onNavLinkClicked = this.onNavLinkClicked.bind(this);
    }

    componentDidMount() {
        // Check the role of the user so the user is only presented options that they are actually permitted to use
        backendGet('ops/user/info', {})
        .then(response => {
            const userRoles = response.data.roles;
            let authorizedComponents = [];
            if (userRoles.includes('FullAccess') || userRoles.includes('ReadOnlyAccess')){
                authorizedComponents.push('TenantMigration');
            }
            this.setState({ authorizationCheckInProgress: false, authorizedComponents: authorizedComponents });
        })
        .catch(error => {
            this.setState({ authorizationCheckInProgress: false, authorizedComponents: [] });
        })
    }

    render () {
        const { authorizationCheckInProgress, authorizedComponents, activeNavLink } = this.state;

        //FIXME: nav: this is a temporary hack; integrate react-router
        return (
            <div>
                {(authorizationCheckInProgress) && <Spinner label="Checking user authorization" labelPosition="left" size={SpinnerSize.large} />}
                { (authorizedComponents.includes('TenantMigration')) &&
                    <Stack horizontal tokens={stackTokens}>
                        <NavPane onLinkClicked={this.onNavLinkClicked} selectedKey={activeNavLink}/>
                        {(activeNavLink === 'Migration_Status') && <TenantMigrationStatusList />}
                    </Stack>
                }
                { (!authorizationCheckInProgress && !authorizedComponents.includes('TenantMigration')) &&
                    <MessageBar messageBarType={MessageBarType.blocked}><b>Unauthorized.</b> You are not authorized to use this application.</MessageBar>
                }
            </div>
        );
    }

    onNavLinkClicked(key) {
        this.setState({activeNavLink: key});
    }

}
