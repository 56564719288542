import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar'
import { DetailsList, CheckboxVisibility, ConstrainMode, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';

import { backendGet } from './BackendClient';
import { TenantMigrationStatus } from './TenantMigrationStatus';

const stackTokensDialog = {
    childrenGap: 15,
    padding: 15,
};

const stackTokensFilters = {
    childrenGap: 20,
};

const tenantListColumns = [
    {
        key: 'Tenant',
        name: 'Tenant',
        fieldName: 'tenant',
        minWidth: 250,
        maxWidth: 300,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
    },
    {
        key: 'AFS2Timestamp',
        name: 'Last refresh',
        fieldName: 'timestamp',
        minWidth: 250,
        maxWidth: 300,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS2Known',
        name: 'AFS2:Known',
        fieldName: 'afs2AgentsKnown',
        ariaLabel: 'Agents known by AFS2',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS2KnownRatio',
        name: 'AFS2:Known vs AFS1',
        fieldName: 'ratioKnownAFS1vsAFS2',
        ariaLabel: 'Ratio of agents known to AFS2 vs AFS1',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS2Reporting',
        name: 'AFS2:Reporting',
        fieldName: 'afs2AgentsReportingToAFS2',
        ariaLabel: 'Agents reporting to AFS2',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS2ReportingRatio',
        name: 'AFS2:Reporting ratio',
        fieldName: 'ratioAFS2Reporting',
        ariaLabel: 'Agents reporting to AFS2 as a ratio of the total number of known agents',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS2NotYetReporting',
        name: 'AFS2:Not yet reporting',
        fieldName: 'afs2AgentsNotYetReportingToAFS2',
        ariaLabel: 'Agents not yet reporting to AFS2',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS2InTransition',
        name: 'AFS2:In transition',
        fieldName: 'afs2AgentsInTransition',
        ariaLabel: 'Agents in transition (AFS2)',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS2PoliciesUpToDate',
        name: 'AFS2:Policy up-to-date',
        fieldName: 'afs2AgentsPoliciesUpToDate',
        ariaLabel: 'Agents with an up-to-date AFS2 policy',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS2PoliciesUpToDateRatio',
        name: 'AFS2:Policy up-to-date ratio',
        fieldName: 'ratioAFS2PolicyUpToDate',
        ariaLabel: 'Agents with an up-to-date AFS2 policy as a ratio of the total number of known agents',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS2CredentialsUpToDate',
        name: 'AFS2:Credentials up-to-date',
        fieldName: 'afs2AgentsCredentialsUpToDate',
        ariaLabel: 'Agents with up-to-date credentials (AFS2)',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS1Known',
        name: 'AFS1:Known',
        fieldName: 'afs1TotalAgents',
        ariaLabel: 'Agents known by AFS1',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS1CheckedInSinceMigration',
        name: 'AFS1:Checked in since migration',
        fieldName: 'afs1AgentsCheckedInSinceMigration',
        ariaLabel: 'Agents that checked in to AFS1 since the migration',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS1Silent30Days',
        name: 'AFS1:30 days silent',
        fieldName: 'afs1AgentsSilent30Days',
        ariaLabel: 'Agents that have not checked in to AFS1 for 30 days',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS1Silent60Days',
        name: 'AFS1:60 days silent',
        fieldName: 'afs1AgentsSilent60Days',
        ariaLabel: 'Agents that have not checked in to AFS1 for 60 days',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS1Silent90Days',
        name: 'AFS1:90 days silent',
        fieldName: 'afs1AgentsSilent90Days',
        ariaLabel: 'Agents that have not checked in to AFS1 for 90 days',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS1Silent120Days',
        name: 'AFS1:120 days silent',
        fieldName: 'afs1AgentsSilent120Days',
        ariaLabel: 'Agents that have not checked in to AFS1 for 120 days',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
    {
        key: 'AFS1Lost',
        name: 'AFS1:Lost',
        fieldName: 'afs1AgentsLost',
        ariaLabel: 'Agents that have not checked in to AFS1 for more than 150 days and are considered lost',
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: true,
    },
];

function sortList (items, key, ascending) {
    if (key === 'tenant') {
        if (ascending) {
            return items.slice().sort((a, b) => (a[key].localeCompare(b[key], 'en', {'sensitivity': 'base'})));
        } else {
            return items.slice().sort((a, b) => (b[key].localeCompare(a[key], 'en', {'sensitivity': 'base'})));
        }
    } else {
        return items.slice().sort((a, b) => ((ascending ? a[key] > b[key] : a[key] < b[key]) ? 1 : -1));
    }
}

function renderHeaderTooltip(props, defaultRender) {
    return defaultRender({
            ...props,
            onRenderColumnHeaderTooltip: (tooltipHostProps) => {
                return (
                    <TooltipHost {...tooltipHostProps} />
                )
            }
        });
}

function renderItemColumn(item, index, column) {
    if (column.key === 'Tenant') {
        const tenantName = item['tenantName'];
        const tenantId = item['tenantId'];
        if (tenantName) {        
            return <div><b>{tenantName}</b>&emsp;<span style={{color: 'lightgrey'}}>({tenantId})</span></div>;
        } else {
            return <span><b>{tenantId}</b></span>;
        }
    }
    else if (column.key.endsWith('Ratio')) {
        return <span>{Math.floor(item[column.fieldName] * 100)}%</span>;
    } else {
        return <span>{item[column.fieldName]}</span>;
    };
} 

export class TenantMigrationStatusList extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            errorMessage: null,
            activeColumns: tenantListColumns.slice(),
            sortedTenantList: null,
            sortColumnName: 'tenant',
            sortAscending: true,
            selectedTenant: null,
            filterText: '',
            filterCount: 0,
            showDetails: false,
        };

        this.onColumnHeaderClick = this.onColumnHeaderClick.bind(this);
        this.onFilterTextChanged = this.onFilterTextChanged.bind(this);
        this.onFilterCountChanged = this.onFilterCountChanged.bind(this);
    }

    componentDidMount() {
        backendGet('ops/migration/status/get', {})
        .then(response => {
            const tenantList = response.data;

            // Augment data
            tenantList.forEach((tenant) => {
                tenant.tenant = tenant.tenantName ? tenant.tenantName + ' (' + tenant.tenantId + ')' : tenant.tenantId;
                tenant.ratioKnownAFS1vsAFS2 = (tenant.afs1TotalAgents > 0) ? tenant.afs2AgentsKnown / tenant.afs1TotalAgents : 0;
                tenant.ratioAFS2Reporting = (tenant.afs2AgentsKnown > 0) ? tenant.afs2AgentsReportingToAFS2 / tenant.afs2AgentsKnown : 0;
                tenant.ratioAFS2PolicyUpToDate = (tenant.afs2AgentsKnown > 0) ? tenant.afs2AgentsPoliciesUpToDate / tenant.afs2AgentsKnown : 0;
            });    

            this.setState({ sortedTenantList: sortList(tenantList, this.state.sortColumnName, this.state.sortAscending) });
        })
        .catch(error => {
            const errorMessage = "Error retrieving tenants" + (error?.response?.data ? (": " + error.response.data) : "");
            this.setState({ errorMessage: errorMessage });
        });
    }

    onColumnHeaderClick (ev, clickedColumn) {
        const { activeColumns, sortedTenantList } = this.state;

        // Update the column structure tDetermine the new sorting column and/or direction and update the column structure
        let sortColumnName = null;
        let sortAscending = true;
        const columns = activeColumns.slice();

        columns.forEach((column) => {
            if (column.key === clickedColumn.key) {
                column.isSortedDescending = !column.isSortedDescending;
                column.isSorted = true;
                sortColumnName = column.fieldName;
                sortAscending = !column.isSortedDescending
            } else {
                column.isSorted = false;
                column.isSortedDescending = true;
            }
        });

        // Sort the data
        const newlySortedTenantList = sortList(sortedTenantList, sortColumnName, sortAscending);

        // Update state
        this.setState({
            activeColumns: columns,
            sortedTenantList: newlySortedTenantList,
            sortColumnName: sortColumnName,
            sortAscending: sortAscending,
        });
    }

    onFilterTextChanged (ev, newValue) {
        this.setState({ filterText: newValue });
    }

    onFilterCountChanged (ev, newValue) {
        this.setState({ filterCount: newValue });
    }

    getFilteredTenantList () {
        const { sortedTenantList, filterText, filterCount } = this.state;
        return sortedTenantList.filter(i =>
            (filterText === '' || i.tenant.toLowerCase().indexOf(filterText) > -1)
            && (filterCount === '' || isNaN(filterCount) || i.afs1TotalAgents >= filterCount)            
        );
    }

    render () {
        const { sortedTenantList, errorMessage, selectedTenant, filterText, filterCount, activeColumns, showDetails } = this.state;

        return (
            <Stack tokens={stackTokensDialog}>
                <Text variant={'xLarge'}>Tenant migration status</Text>
                {(!sortedTenantList && !errorMessage) && <Spinner label="Fetching data..." labelPosition="left" size={SpinnerSize.large} />}
                {(errorMessage) && <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>{errorMessage}</MessageBar>}
                {(sortedTenantList && !errorMessage) &&
                <Stack>
                    <Stack horizontal tokens={stackTokensFilters}>
                        <TextField label='Filter on tenant' style={{width: 200}} value={filterText} onChange={this.onFilterTextChanged}/>
                        <TextField label='Filter on minimum AFS1 agent count' style={(isNaN(filterCount)) ? {width: 200, color: 'red'} : {width: 200}} value={filterCount} onChange={this.onFilterCountChanged}/>
                    </Stack>
                    <DetailsList
                        checkboxVisibility={CheckboxVisibility.hidden}
                        columns={activeColumns}
                        items={this.getFilteredTenantList()}
                        constrainMode={ConstrainMode.unconstrained}
                        selectionMode={SelectionMode.single}
                        onItemInvoked={(item) => this.setState({ showDetails: true, selectedTenant: item.tenantId })}
                        onColumnHeaderClick={this.onColumnHeaderClick}
                        onRenderDetailsHeader={renderHeaderTooltip}
                        onRenderItemColumn={renderItemColumn}
                    />
                </Stack>
                }

                {(showDetails) && <TenantMigrationStatus tenant={selectedTenant} onDismiss={() => this.setState({ showDetails: false })} />}
            </Stack>
        );
    }
}