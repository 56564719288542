import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import axios from 'axios'

import { frontendHostUrl, backendBaseUrl, backendApplicationId, backendApplicationIdUri, backendTokenAuthority, backendTokenScope } from './DeploymentConfiguration'

const msalInstance = new PublicClientApplication({
    auth: {
        clientId: backendApplicationId,
        authority: backendTokenAuthority,
        redirectUri: frontendHostUrl,
    },
    cache: {
        cacheLocation: 'sessionStorage'
    },
});

async function acquireBackendToken() {
    // Get logged in account
    let currentAccount = null;

    const currentAccounts = msalInstance.getAllAccounts();
    if (currentAccounts) {
        currentAccount = currentAccounts[0];
    }

    if (!currentAccount) {
        // Not yet logged in; get user to log in
        const loginResponse = await msalInstance.loginPopup();
        if (loginResponse) {
            currentAccount = loginResponse.account;
        }
    }

    const accessTokenRequest = {
        account: currentAccount,
        scopes: [`${backendApplicationIdUri}/${backendTokenScope}`],
    };

    return msalInstance.acquireTokenSilent(accessTokenRequest).catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
            return msalInstance.acquireTokenPopup(accessTokenRequest);
        } else {
            throw error;
        }
    });
}

export async function backendGet(requestPath, requestParams) {
    const accessToken = await acquireBackendToken();
    return axios.get(`${backendBaseUrl}/${requestPath}`, {
        headers: {
            'Authorization' : 'Bearer ' + accessToken.accessToken,
        },
        params: requestParams
    });
}

export async function backendPost(requestPath, requestParams) {
    const accessToken = await acquireBackendToken();

    return axios.post(`${backendBaseUrl}/${requestPath}`, null, {
        headers: {
            'Authorization' : 'Bearer ' + accessToken.accessToken,
        },
        params: requestParams
    })
}
