import React from 'react';
import { Nav, INavLink, INavStyles, INavLinkGroup } from 'office-ui-fabric-react/lib/Nav';

const navStyles: Partial<INavStyles> = {
    root: {
        height: '100vh',
        boxSizing: 'border-box',
        border: '1px solid #eee',
        overflowY: 'auto',
    },
};

const navLinkGroups: INavLinkGroup[] = [
    {
        links: [
            {
                name: 'Migration',
                links: [
                    {
                        key: 'Migration_Status',
                        name: 'Status',
                    },
                ],
                isExpanded: true,
            },
        ],
    },
];

export class NavPane extends React.Component {
    constructor (props) {
        super(props);
        this.onLinkClick = this.onLinkClick.bind(this);
    }

    render () {
        return (
            <Nav
                onLinkClick={this.onLinkClick}
                selectedKey={this.props.selectedKey}
                styles={navStyles}
                groups={navLinkGroups}
            />
        );
    }

    onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
        if (item && item.key) {
            this.props.onLinkClicked(item.key);
        }
    }
};


