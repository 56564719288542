import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';

initializeIcons();

ReactDOM.render(
  <Fabric>
    <App />
  </Fabric>,
  document.getElementById('root')
);
