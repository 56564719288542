import React from 'react';
import { Modal } from 'office-ui-fabric-react/lib/Modal';
import { DefaultButton, PrimaryButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DetailsList, CheckboxVisibility } from 'office-ui-fabric-react/lib/DetailsList';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar'
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import { backendGet, backendPost } from './BackendClient';
import { TenantMigrationStatusHistory } from './TenantMigrationStatusHistory';

const maxRetryCount = 30;
const retryIntervalMS = 1000;

const stackTokensDialog = {
    padding: 15,
};

const stackTokens = {
    childrenGap: 15,
    padding: 5,
};

const stackTokensBlock = {
    childrenGap: 60,
    padding: 5,
};

const migrationStateListColumns = [
    {
        key: 'MigrationStateNameKey',
        name: 'Name',
        fieldName: 'name',
        minWidth: 250,
        maxWidth: 300,
        isResizable: true,
    },
    {
        key: 'MigrationStateStateKey',
        name: 'State',
        fieldName: 'state',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
    },
    {
        key: 'MigrationStateDetailsKey',
        name: 'Details',
        fieldName: 'details',
        minWidth: 400,
        maxWidth: 800,
        isResizable: true,
    },
];

export class TenantMigrationStatus extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            errorMessage: '',
            migrationStatistics: null,
            migrationStatisticsAreStale: false,
            fetchingStatistics: true,
            statisticsRefreshRetryCount: 0,
            migrationAnalysis: null,
            fetchingAnalysis: true,
            showAFS1StatisticsHistory: false,
            showAFS2StatisticsHistory: false,
        };

        this.onDismiss = this.onDismiss.bind(this);
        this.onDismissErrorMessage = this.onDismissErrorMessage.bind(this);
        this.onRefreshClicked = this.onRefreshClicked.bind(this);
    }

    componentDidMount() {
        backendGet('ops/migration/statistics/get', {
            tenant: this.props.tenant,
        })
        .then(response => {
            this.setState({ fetchingStatistics: false, migrationStatistics: response.data });
        })
        .catch(error => {
            const errorMessage = "Error retrieving AFS1 statistics" + (error?.response?.data ? (": " + error.response.data) : "");
            this.setState({ fetchingStatistics: false, errorMessage: errorMessage });
        });       

        backendGet('ops/migration/analyzer/tenant_analysis/get', {
            tenant: this.props.tenant,
        })
        .then(response => {
            this.setState({ fetchingAnalysis: false, migrationAnalysis: response.data });
        })
        .catch(error => {
            const errorMessage = "Error retrieving AFS2 statistics" + (error?.response?.data ? (": " + error.response.data) : "");
            this.setState({ fetchingAnalysis: false, errorMessage: errorMessage });
        });
    }

    clearMessages () {
        this.setState({ errorMessage: '' });
    }

    requestStatisticsRefresh() {
        this.setState({ fetchingStatistics: true, migrationStatisticsAreStale: true, statisticsRefreshRetryCount: 0 });

        // First get the most recent statistics
        backendGet('ops/migration/statistics/get', {
            tenant: this.props.tenant,
        })
        .then(response => {
            const currentStatistics = response.data;

            // Check if a migration timestamp is already available (i.e. if statistics have been requested before)
            if (currentStatistics) {
                this.setState({ migrationStatistics: currentStatistics });
                backendPost('ops/migration/statistics/refresh', {
                    tenant: this.props.tenant
                })
                .then(response => {
                    // Refresh request accepted, now schedule result retrieval
                    setTimeout(this.getRefreshedStatistics.bind(this), retryIntervalMS);
                })
                .catch(error => {
                    const message = "AFS1 statistics refresh request failed" + (error?.response?.data ? (": " + error.response.data) : "");
                    this.setState({ fetchingStatistics: false, errorMessage: message });
                });
            }
            else
            {
                // Cannot get current statistics (should not occur)
                const errorMessage = "AFS1 statistics refresh request failed, error retrieving current statistics"
                this.setState({ fetchingStatistics: false, errorMessage: errorMessage });
            }
        })
        .catch(error => {
            const errorMessage = "AFS1 statistics refresh request failed, error retrieving current statistics" + (error?.response?.data ? (": " + error.response.data) : "");
            this.setState({ fetchingStatistics: false, errorMessage: errorMessage });
        });       
    }

    getRefreshedStatistics() {
        // Get statistics
        backendGet('ops/migration/statistics/get', {
                tenant: this.props.tenant,
        })
        .then(response => {
            const newStatistics = response.data;

            // Determine if the new statistics differ from the ones we retrieved earlier
            const currentStatistics = this.state.migrationStatistics;
            if (newStatistics != null && (currentStatistics == null || newStatistics.timestamp !== currentStatistics.timestamp)) {
                this.setState({ fetchingStatistics: false, migrationStatistics: newStatistics, migrationStatisticsAreStale: false });
            }
            else {
                // We didn't get new statistics, schedule a retry
                const { statisticsRefreshRetryCount } = this.state;
                if (statisticsRefreshRetryCount < maxRetryCount) {
                    this.setState({ statisticsRefreshRetryCount : statisticsRefreshRetryCount + 1 });
                    setTimeout(this.getRefreshedStatistics.bind(this), retryIntervalMS);
                }
                else {
                    this.setState({ fetchingStatistics: false, errorMessage: 'Error retrieving AFS1 statistics: no (new) statistics available within timeout interval'});
                }
            }
        })
        .catch(error => {
            const errorMessage = "Error retrieving AFS1 statistics" + (error?.response?.data ? (": " + error.response.data) : "");
            this.setState({ fetchingStatistics: false, errorMessage: errorMessage });
        });       
    }

    getRefreshedAnalysis() {
        this.setState({ fetchingAnalysis: true });
        backendGet('ops/migration/analyzer/tenant', {
                tenant: this.props.tenant,
        })
        .then(response => {
            this.setState({ fetchingAnalysis: false, migrationAnalysis: response.data });
        })
        .catch(error => {
            const errorMessage = "Error retrieving AFS2 statistics" + (error?.response?.data ? (": " + error.response.data) : "");
            this.setState({ migrationAnalysis: null, fetchingAnalysis: false, errorMessage: errorMessage });
        });
    }

    showAFS1Statistics () {
        const { migrationStatistics, migrationStatisticsAreStale } = this.state;

        return (
            <Stack>
                <Stack horizontal tokens={stackTokens}>
                    <Label style={{width: 180}}>Total agents known</Label>
                    <TextField readOnly style={{width: 70}} value={migrationStatistics.totalAfs1Agents} />
                </Stack>
                <Stack horizontal tokens={stackTokens}>
                    <Label style={{width: 180}}>Checked in since migration</Label>
                    <TextField readOnly style={{width: 70}} value={migrationStatistics.agentsCheckedInSinceMigration} />
                </Stack>
                <Stack horizontal tokens={stackTokens}>
                    <Label style={{width: 180}}>Agents not heard from for</Label>
                    <TextField label="30 days" readOnly style={{width: 70}} value={migrationStatistics.agentsSilent30Days} />
                    <TextField label="60 days" readOnly style={{width: 70}} value={migrationStatistics.agentsSilent60Days} />
                    <TextField label="90 days" readOnly style={{width: 70}} value={migrationStatistics.agentsSilent90Days} />
                    <TextField label="120 days" readOnly style={{width: 70}} value={migrationStatistics.agentsSilent120Days} />
                </Stack>
                <Stack horizontal tokens={stackTokens}>
                    <Label style={{width: 180}}>Agents considered lost</Label>
                    <TextField readOnly style={{width: 70}} value={migrationStatistics.agentsLost} />
                </Stack>
                <br />
                <DefaultButton text="History" onClick={() => this.setState({ showAFS1StatisticsHistory: true })}/>
                {(migrationStatisticsAreStale) &&
                <Stack.Item align="center">
                    <br />
                    <Text style={{color: 'red'}}>(Warning, stale data! Last update (UTC): {migrationStatistics.timestamp})</Text>
                </Stack.Item>}
            </Stack>
        );
    }

    showMigrationStates () {
        const { migrationStatistics, migrationStatisticsAreStale } = this.state;

        return (
            <div>
                {(migrationStatistics.migrationStates) && 
                <Stack>
                    <DetailsList
                            checkboxVisibility={CheckboxVisibility.hidden}
                            columns={migrationStateListColumns}
                            items={migrationStatistics.migrationStates}
                    />
                    {(migrationStatisticsAreStale) &&
                    <Stack.Item align="center">
                        <br />
                        <Text style={{color: 'red'}}>(Warning, stale data! Last update (UTC): {migrationStatistics.timestamp})</Text>
                    </Stack.Item>}
                </Stack>}
                {(!migrationStatistics.migrationStates) && <Text>No migration states available</Text>}
            </div>
        );
    }

    showAFS2Statistics () {
        const { migrationAnalysis } = this.state;

        return (
            <Stack>
                <Stack horizontal tokens={stackTokens}>
                    <Label style={{width: 250}}>Total agents known</Label>
                    <TextField readOnly style={{width: 70}} value={migrationAnalysis.agentsKnown} />
                </Stack>
                <Stack horizontal tokens={stackTokens}>
                    <Label style={{width: 250}}>Agents reporting</Label>
                    <TextField readOnly style={{width: 70}} value={migrationAnalysis.agentsReportingToAFS2} />
                </Stack>
                <Stack horizontal tokens={stackTokens}>
                    <Label style={{width: 250}}>Agents not yet reporting</Label>
                    <TextField readOnly style={{width: 70}} value={migrationAnalysis.agentsNotYetReportingToAFS2} />
                </Stack>
                <Stack horizontal tokens={stackTokens}>
                    <Label style={{width: 250}}>Agents in transition</Label>
                    <TextField readOnly style={{width: 70}} value={migrationAnalysis.agentsInTransition} />
                </Stack>
                <Stack horizontal tokens={stackTokens}>
                    <Label style={{width: 250}}>Agents with up-to-date policy</Label>
                    <TextField readOnly style={{width: 70}} value={migrationAnalysis.agentsPoliciesUpToDate} />
                </Stack>
                <Stack horizontal tokens={stackTokens}>
                    <Label style={{width: 250}}>Agents with up-to-date credentials</Label>
                    <TextField readOnly style={{width: 70}} value={migrationAnalysis.agentsCredentialsUpToDate} />
                </Stack>
                <br />
                <DefaultButton text="History" onClick={() => this.setState({ showAFS2StatisticsHistory: true })}/>
            </Stack>
        );
    }

    render () {
        const { errorMessage, migrationStatistics, fetchingStatistics, migrationAnalysis, fetchingAnalysis, showAFS1StatisticsHistory, showAFS2StatisticsHistory } = this.state;

        return (
            <Modal
                isOpen={true}
                onDismiss={this.onDismiss}
            >
                <Stack tokens={stackTokensDialog} styles={{root: {maxHeight: '90vh'}}}>
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack.Item align="start">
                            <Text variant={'xLarge'}>Tenant migration status</Text>
                        </Stack.Item>
                        <Stack.Item align="end">
                            <IconButton iconProps={{iconName: 'Cancel'}} onClick={this.onDismiss} />
                        </Stack.Item>
                    </Stack>
                    <br />
                    {(errorMessage !== '') && <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.onDismissErrorMessage}>{errorMessage}</MessageBar>}
                    <Stack horizontal tokens={stackTokens}>
                        <Label>Tenant</Label>                    
                        <TextField readOnly style={{width: 300}} value={this.props.tenant}/>
                        <PrimaryButton text="Refresh" onClick={this.onRefreshClicked} disabled={(fetchingStatistics || fetchingAnalysis)}/>
                    </Stack>

                    {(migrationStatistics) &&
                    <div>
                        <br />
                        <Stack horizontal tokens={stackTokens}>
                            <Label style={{width: 140}}>Migration timestamp</Label>
                            <TextField readOnly style={{width: 250}} value={migrationStatistics.migrationTimestamp} />
                        </Stack>
                        <br />
                    </div>}
                
                    {(fetchingAnalysis || fetchingStatistics || migrationAnalysis || migrationStatistics) &&
                    <div>
                        <br />
                        <Stack horizontal tokens={stackTokensBlock}>
                            <Stack>
                                <br />
                                <Separator style={{height: 200}}>Migration status according to AFS2</Separator>
                                <br />
                                {(fetchingAnalysis) && <Spinner label="Fetching data..." labelPosition="left" size={SpinnerSize.large} />}
                                {(!fetchingAnalysis && migrationAnalysis) && this.showAFS2Statistics()}
                            </Stack>
                            <Separator vertical />
                            <Stack>
                                <br />
                                <Separator style={{height: 200}}>Migration status according to AFS1</Separator>
                                <br />
                                {(fetchingStatistics) && <Spinner label="Fetching data..." labelPosition="left" size={SpinnerSize.large} />}
                                {(!fetchingStatistics && migrationStatistics) && this.showAFS1Statistics()}
                            </Stack>
                        </Stack>
                        <br />
                        <br />
                        <Separator>Migration states</Separator>
                        {(fetchingStatistics) && <Spinner label="Fetching data..." labelPosition="left" size={SpinnerSize.large} />}
                        {(!fetchingStatistics && migrationStatistics) && this.showMigrationStates()}
                    </div>}

                    {(showAFS2StatisticsHistory) && 
                    <TenantMigrationStatusHistory
                        tenant={this.props.tenant}
                        source='afs2'
                        onDismiss={() => this.setState({ showAFS2StatisticsHistory: false })}
                    />}
                    {(showAFS1StatisticsHistory) && 
                    <TenantMigrationStatusHistory
                        tenant={this.props.tenant}
                        source='afs1'
                        onDismiss={() => this.setState({ showAFS1StatisticsHistory: false })}
                    />}
                </Stack>
            </Modal>
        );
    }

    onDismiss () {
        this.props.onDismiss();
    }
    
    onDismissErrorMessage () {
        this.setState({ errorMessage: '' });
    }
 
    onRefreshClicked () {
        this.clearMessages();
        this.requestStatisticsRefresh();
        this.getRefreshedAnalysis();
    }
}