import React from 'react';
import { Modal } from 'office-ui-fabric-react/lib/Modal';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar'
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { DetailsList, CheckboxVisibility } from 'office-ui-fabric-react/lib/DetailsList';

import { backendGet } from './BackendClient';

const stackTokens = {
    childrenGap: 15,
    padding: 15,
};

const afs1StatisticsHistoryListColumns = [
    {
        key: 'AFS1HistTimestamp',
        name: 'Timestamp',
        fieldName: 'timestamp',
        minWidth: 250,
        maxWidth: 300,
        isResizable: true,
    },
    {
        key: 'AFS1HistKnown',
        name: 'Known',
        fieldName: 'totalAfs1Agents',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'AFS1CheckedInSinceMigration',
        name: 'Checked in since migration',
        fieldName: 'agentsCheckedInSinceMigration',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'AFS1Silent30Days',
        name: '30 days silent',
        fieldName: 'agentsSilent30Days',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'AFS1Silent60Days',
        name: '60 days silent',
        fieldName: 'agentsSilent60Days',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'AFS1Silent90Days',
        name: '90 days silent',
        fieldName: 'agentsSilent90Days',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'AFS1Silent120Days',
        name: '120 days silent',
        fieldName: 'agentsSilent120Days',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'AFS1Lost',
        name: 'Lost',
        fieldName: 'agentsLost',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
];

const afs2StatisticsHistoryListColumns = [
    {
        key: 'AFS2HistTimestamp',
        name: 'Timestamp',
        fieldName: 'timestamp',
        minWidth: 250,
        maxWidth: 300,
        isResizable: true,
    },
    {
        key: 'AFS2HistKnown',
        name: 'Known',
        fieldName: 'agentsKnown',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'AFS2HistReporting',
        name: 'Reporting',
        fieldName: 'agentsReportingToAFS2',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'AFS2HistNotYetReporting',
        name: 'Not yet reporting',
        fieldName: 'agentsNotYetReportingToAFS2',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'AFS2HistInTransition',
        name: 'In transition',
        fieldName: 'agentsInTransition',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'AFS2HistPoliciesUpToDate',
        name: 'Policy up-to-date',
        fieldName: 'agentsPoliciesUpToDate',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'AFS2HistCredentialsUpToDate',
        name: 'Credentials up-to-date',
        fieldName: 'agentsCredentialsUpToDate',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
];

// Helper function for sorting the history
function compareTimestampDescending(lhs, rhs) {
    if (lhs.timestamp < rhs.timestamp) {
        return 1;
    }
    if (lhs.timestamp > rhs.timestamp) {
        return -1;
    }
    return 0;
}

// Helper function for filtering duplicates
function equalValues(lhs, rhs, type) {
    if (type === 'afs1') {
        return lhs.totalAfs1Agents === rhs.totalAfs1Agents
                && lhs.agentsCheckedInSinceMigration === rhs.agentsCheckedInSinceMigration
                && lhs.agentsSilent30Days === rhs.agentsSilent30Days
                && lhs.agentsSilent60Days === rhs.agentsSilent60Days
                && lhs.agentsSilent90Days === rhs.agentsSilent90Days
                && lhs.agentsSilent120Days === rhs.agentsSilent120Days
                && lhs.agentsLost === rhs.agentsLost;
    } else {
        return lhs.agentsKnown === rhs.agentsKnown
                && lhs.agentsReportingToAFS2 === rhs.agentsReportingToAFS2
                && lhs.agentsNotYetReportingToAFS2 === rhs.agentsNotYetReportingToAFS2
                && lhs.agentsInTransition === rhs.agentsInTransition
                && lhs.agentsPoliciesUpToDate === rhs.agentsPoliciesUpToDate
                && lhs.agentsCredentialsUpToDate === rhs.agentsCredentialsUpToDate;
    }
}

export class TenantMigrationStatusHistory extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            statistics: null,
            filteredStatistics: null,
            showAll: false,
            errorMessage: null,
        };

        this.onDismiss = this.onDismiss.bind(this);
        this.onShowAllToggleChange = this.onShowAllToggleChange.bind(this);
    }

    componentDidMount() {
        // Get statistics from AFS1 or AFS2?
        let statisticsUrl = 'ops/migration/analyzer/tenant_analysis/get';
        if (this.props.source === 'afs1') {
            statisticsUrl = 'ops/migration/statistics/get';
        }

        backendGet(statisticsUrl, {
            tenant: this.props.tenant,
            include_history: true    // This is actually a value-less parameter, but this version of Axios doesn't support that yet
        })
        .then(response => {
            const statistics = response.data.sort(compareTimestampDescending);

            // Filter out records where there was no change in values wrt the prior record
            let filteredStatistics = [];
            const recordCount = statistics.length;
            if (recordCount > 0)
            {
                filteredStatistics.push(statistics[recordCount-1]);
                for (let i = recordCount - 2; i >= 0; i--) {
                    if (!equalValues(statistics[i], filteredStatistics[0], this.props.source))
                    {
                        filteredStatistics.unshift(statistics[i]);
                    }
                }
            }

            this.setState({ statistics: statistics, filteredStatistics: filteredStatistics });
        })
        .catch(error => {
            const errorMessage = "Error retrieving statistics history" + (error?.response?.data ? (": " + error.response.data) : "");
            this.setState({ errorMessage: errorMessage });
        });
    }

    onDismiss () {
        this.props.onDismiss();
    }

    onShowAllToggleChange(ev, checked) {
        this.setState({ showAll: checked });
    }
      
    render () {
        const { statistics, filteredStatistics, showAll, errorMessage } = this.state;

        return (
            <Modal
                isOpen={true}
                onDismiss={this.onDismiss}
            >
                <Stack tokens={stackTokens} styles={{root: {maxHeight: '90vh'}}}>
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack.Item align="start">
                            <Text variant={'large'}>Migration statistics history [{(this.props.source === 'afs1') ? 'AFS1' : 'AFS2'}]</Text>
                        </Stack.Item>
                        <Stack.Item align="end">
                            <IconButton iconProps={{iconName: 'Cancel'}} onClick={this.onDismiss} />
                        </Stack.Item>
                    </Stack>

                    {(!statistics && !errorMessage) && <Spinner label="Fetching data..." labelPosition="left" size={SpinnerSize.large} />}
                    {(errorMessage) && <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>{errorMessage}</MessageBar>}
                    {(statistics && !errorMessage) &&
                    <div>
                        <Toggle label="Show all" onText="On" offText="Off" onChange={this.onShowAllToggleChange} />
                        <DetailsList
                            checkboxVisibility={CheckboxVisibility.hidden}
                            columns={(this.props.source === 'afs1') ? afs1StatisticsHistoryListColumns : afs2StatisticsHistoryListColumns}
                            items={(showAll) ? statistics : filteredStatistics}
                        />
                    </div>}
                </Stack>
            </Modal>
        );
    }
}